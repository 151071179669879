import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/poll-text.png'
import _imports_1 from '@/assets/poll-page.png'
import _imports_2 from '@/assets/lead-text.png'
import _imports_3 from '@/assets/lead-page.png'


const _hoisted_1 = { class: "mobile-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"section has-text-centered\"><div class=\"highlight-text has-text-weight-semibold\">Our Platform</div><br><div class=\"mb-6 is-size-2\">Interactive Messaging</div><br><br><div class=\"columns is-vcentered is-centered is-gapless has-text-centered has-text-weight-bold\"><div class=\"column is-narrow\"><div class=\"mb-6 highlight-text is-size-4\">SMS / MMS with Poll</div><div class=\"columns is-centered is-gapless is-vcentered\"><div class=\"column is-narrow\"><div class=\"pr-6\"><img src=\"" + _imports_0 + "\"></div></div><div class=\"column is-narrow\"><img src=\"" + _imports_1 + "\"></div></div></div><div class=\"column is-narrow is-hidden-mobile\"><div class=\"middle-divider\"></div></div><div class=\"column is-narrow\"><div class=\"mb-6 highlight-text is-size-4\">SMS / MMS with Mobile Page</div><div class=\"columns is-gapless is-vcentered is-centered\"><div class=\"column is-narrow\"><div class=\"pr-6\"><img src=\"" + _imports_2 + "\"></div></div><div class=\"column is-narrow\"><img src=\"" + _imports_3 + "\"></div></div></div></div></div>", 1)
  ])))
}