import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "max-width-container" }
const _hoisted_2 = { class: "columns is-gapless" }
const _hoisted_3 = { class: "column is-half" }
const _hoisted_4 = { class: "mt-6" }
const _hoisted_5 = { class: "columns is-multiline is-mobile is-vcentered" }
const _hoisted_6 = { class: "column is-11" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "difference-section",
    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgImg + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "grey-logo-background section",
            style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.greyLogo + ')' })
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "has-text-weight-semibold highlight-text" }, "Our Unique Approach", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-2 is-size-2" }, "AlertTek Difference", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "column is-1" }, [
                      _createElementVNode("div", { class: "is-size-7 highlight-text icon-circle" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", null, _toDisplayString(i), 1)
                    ])
                  ], 64))
                }), 256))
              ])
            ])
          ], 4)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "column is-half" }, null, -1))
      ])
    ])
  ], 4))
}