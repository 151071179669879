import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = {
  class: "navbar is-transparent",
  role: "navigation",
  "aria-label": "main navigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "navbar-brand" }, [
      _createElementVNode("a", {
        class: "navbar-item",
        href: "https://alerttek.com"
      }, [
        _createElementVNode("img", { src: _imports_0 })
      ])
    ], -1)
  ])))
}