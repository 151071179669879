import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "impact-section" }
const _hoisted_2 = { class: "max-width-container" }
const _hoisted_3 = { class: "columns is-gapless" }
const _hoisted_4 = { class: "column is-half" }
const _hoisted_5 = { class: "section" }
const _hoisted_6 = { class: "columns is-mobile is-multiline" }
const _hoisted_7 = { class: "column is-11" }
const _hoisted_8 = { class: "has-text-weight-semibold" }
const _hoisted_9 = { class: "columns is-mobile is-multiline" }
const _hoisted_10 = { class: "column is-11" }
const _hoisted_11 = { class: "has-text-weight-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"column is-half\"><div class=\"section\"><div class=\"has-text-weight-bold highlight-text\">Our Channels</div><div class=\"mt-4\"><div class=\"is-size-2\">FCC / TCPA Impact to Messaging</div></div><div class=\"mt-4\"><div class=\"highlight-text is-size-5\">A2P &amp; P2P channels</div></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-6 highlight-text is-size-4" }, [
              _createElementVNode("span", { class: "mr-2 has-text-weight-bold" }, "Application-To-Person"),
              _createElementVNode("span", null, "(A2P)")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (i) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "column is-1" }, [
                    _createElementVNode("div", { class: "highlight-text is-size-7 icon-circle" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(i), 1)
                  ])
                ], 64))
              }), 256))
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "highlight-break" }, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mt-6 mb-6 highlight-text is-size-4" }, [
              _createElementVNode("span", { class: "mr-2 has-text-weight-bold" }, "Person-To-Person"),
              _createElementVNode("span", null, "(P2P)")
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pList, (i) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "column is-1" }, [
                    _createElementVNode("div", { class: "highlight-text is-size-7 icon-circle" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(i), 1)
                  ])
                ], 64))
              }), 256))
            ])
          ])
        ])
      ])
    ])
  ]))
}