import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-sections" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeHeader = _resolveComponent("HomeHeader")!
  const _component_Communication = _resolveComponent("Communication")!
  const _component_Key = _resolveComponent("Key")!
  const _component_Mobile = _resolveComponent("Mobile")!
  const _component_Impact = _resolveComponent("Impact")!
  const _component_Difference = _resolveComponent("Difference")!
  const _component_Client = _resolveComponent("Client")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeHeader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Communication),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "red-break" }, null, -1)),
      _createVNode(_component_Key),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "red-break" }, null, -1)),
      _createVNode(_component_Mobile),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "red-break" }, null, -1)),
      _createVNode(_component_Impact),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "red-break" }, null, -1)),
      _createVNode(_component_Difference),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "red-break" }, null, -1)),
      _createVNode(_component_Client),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "red-break" }, null, -1))
    ])
  ]))
}