import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-red.png'
import _imports_1 from '@/assets/logo.png'


const _hoisted_1 = {
  class: "max-width-container section",
  style: {"padding-top":"2rem","padding-bottom":"2rem"}
}
const _hoisted_2 = { class: "columns is-vcentered" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "mt-2 has-text-white is-size-7" }
const _hoisted_5 = { class: "column is-narrow" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = {
  style: {"text-decoration":"none"},
  target: "_blank",
  title: "Click for the Business Profile for AlertTek, LLC",
  href: "https://www.bbb.org/us/nc/asheville/profile/communication-consultant/alerttek-llc-0473-814748?utm_campaign=bbb_seal&utm_content=AlertTek%2C%20LLC&utm_medium=website&utm_source=seal_click_814748"
}
const _hoisted_8 = { style: {"display":"block"} }
const _hoisted_9 = { style: {"color":"#fff","font-size":"10px","font-weight":"900","font-family":"Arial, Helvetica, sans-serif"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BBB = _resolveComponent("BBB")!

  return (_openBlock(), _createElementBlock("div", {
    class: "contact-ribbon",
    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.imagePath + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "column is-narrow" }, [
          _createElementVNode("div", { class: "mr-6" }, [
            _createElementVNode("img", {
              src: _imports_0,
              style: {"max-width":"100px"}
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_1,
            style: {"max-width":"200px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"mb-2\">General Sales: Sales@AlertTek.us</div><div class=\"mb-2\">Congressional District Sales: CDSales@AlertTek.us</div><div class=\"mb-2\"><a href=\"tel:2026705640\">(202) 670-5640</a></div><div class=\"mb-2 has-text-weight-bold\">Copyright AlertTek LLC 2023</div>", 4)),
            _createVNode(_component_router_link, {
              class: "has-text-white",
              to: {name: 'Terms'},
              title: "Click for the Terms and Conditions of AlertTek,LLC",
              tag: "a"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Terms & Conditions")
              ])),
              _: 1
            }),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_router_link, {
              class: "has-text-white",
              to: {name: 'Policy'},
              title: "Click for the Privacy Policy of AlertTek,LLC",
              tag: "a"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Privacy Policy")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", _hoisted_7, [
                _cache[6] || (_cache[6] = _createElementVNode("img", {
                  alt: "Click for the Business Profile for AlertTek, LLC",
                  src: "https://www.bbb.org/TerminusContent/dist/img/BBB_US_Torch_sm.svg",
                  style: {"width":"53"},
                  width: "53px"
                }, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_BBB, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("RATING: A+")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("p", null, "Schedule a Demo", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("a", {
              class: "has-text-white has-text-weight-bold is-size-5",
              target: "_blank",
              href: "mailto:sales@alerttek.us"
            }, "sales@alerttek.us", -1))
          ])
        ])
      ])
    ])
  ], 4))
}