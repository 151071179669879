import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "hero-head" }
const _hoisted_2 = { class: "hero-body" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  key: 0,
  class: "columns is-vcentered"
}
const _hoisted_5 = { class: "column" }
const _hoisted_6 = {
  class: "has-text-white is-size-1",
  style: {"max-width":"900px"}
}
const _hoisted_7 = { class: "has-text-weight-bold" }
const _hoisted_8 = {
  key: 0,
  class: "has-text-white"
}
const _hoisted_9 = { class: "is-size-4" }
const _hoisted_10 = {
  key: 0,
  class: "column is-half"
}
const _hoisted_11 = { class: "bullet-point" }
const _hoisted_12 = {
  key: 1,
  class: "columns is-vcentered"
}
const _hoisted_13 = { class: "hero-foot" }
const _hoisted_14 = { class: "columns is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: "home-hero hero is-fullheight",
    style: _normalizeStyle({ backgroundImage: 'linear-gradient(45deg, ' + _ctx.slides[_ctx.activeTab].rgbas + '), url(' + _ctx.slides[_ctx.activeTab].heroBackground + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NavBar)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.activeTab !== 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.slides[_ctx.activeTab].title), 1)
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                (_ctx.slides[_ctx.activeTab].subtitle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.slides[_ctx.activeTab].subtitle), 1)
                    ]))
                  : _createCommentVNode("", true),
                _cache[5] || (_cache[5] = _createElementVNode("a", {
                  class: "mt-6 button is-danger",
                  target: "_blank",
                  href: "mailto:sales@alerttek.us"
                }, "Schedule A Demo", -1))
              ]),
              (_ctx.slides[_ctx.activeTab].hasBullets)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides[_ctx.activeTab].bullets, (i) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(i), 1))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[6] || (_cache[6] = [
              _createStaticVNode("<div class=\"column\"><div class=\"mb-6 has-text-white is-size-1\" style=\"max-width:900px;\"><div class=\"has-text-weight-bold\"><p>Major Carrier</p><p>Approved Channels</p></div></div><div class=\"columns\"><div class=\"column is-narrow is-size-5\"><div class=\"has-text-white\">Shortcodes</div></div><div class=\"column is-narrow is-size-5 column-border-left\"><div class=\"has-text-white\">Toll Free Numbers (TFN)</div></div><div class=\"column is-narrow is-size-5 column-border-left\"><div class=\"has-text-white\">10DLC (Long Codes)</div></div></div><a class=\"mt-6 button is-danger\" target=\"_blank\" href=\"mailto:sales@alerttek.us\">Schedule A Demo</a></div><div class=\"column is-half\"><div class=\"bullet-point\"><div class=\"mb-4\">TFN Communications</div><ul><li>Congressional Constituent (Federal)</li><li>State / Municipal Constituent</li><li>Non-First Responder Emergency</li></ul></div><div class=\"bullet-point\"><div class=\"mb-4\">10DLC Communications</div><ul><li>Constituent</li><li>Non-First Responder Emergency</li></ul></div><div class=\"bullet-point\">Short Code for GOTV</div></div>", 2)
            ])))
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", {
          class: "column is-narrow",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchTab(0)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["icon-circle", { 'highlight-text': _ctx.activeTab === 0 }])
          }, null, 2)
        ]),
        _createElementVNode("div", {
          class: "column is-narrow",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchTab(1)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["icon-circle", { 'highlight-text': _ctx.activeTab === 1 }])
          }, null, 2)
        ]),
        _createElementVNode("div", {
          class: "column is-narrow",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchTab(2)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["icon-circle", { 'highlight-text': _ctx.activeTab === 2 }])
          }, null, 2)
        ]),
        _createElementVNode("div", {
          class: "column is-narrow",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.switchTab(3)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["icon-circle", { 'highlight-text': _ctx.activeTab === 3 }])
          }, null, 2)
        ])
      ])
    ])
  ], 4))
}